import request from "@/utils/request";
import Cookies from "js-cookie";

//获取我的常用办事项
export function revisePassword(params) {
  return request({
    url: "/staff/revisePassword",
    method: "get",
    params: params,
  });
}
//修改职工信息
export function staffEdit(data) {
  return request({
    url: "/staff/edit",
    method: "post",
    data: data,
  });
}

/**
 * @description: 获取个人中心信息
 * @param {Number}
 */

export function getMyStatffInfo(params) {
  return request({
    method: "GET",
    url: "/staff/getMyStatffInfo",
    params: params,
  });
}
/**
 * @description: 修改手机号
 * @param {Number}
 */

export function revisePhone(params) {
  return request({
    method: "GET",
    url: "/staff/revisePhone",
    params: params,
  });
}
/**
 * @description: 个人查看工资单密码验证
 * @param {Number} password
 */

export function salaryPwdValid(params) {
  return request({
    method: "GET",
    url: "/staff/salaryPwdValid",
    params: params,
  });
}
/**
 * @description: 获取短信验证码
 * @param {Number} password
 */

export function getPhoneCode(params) {
  return request({
    method: "GET",
    url: "/staff/getPhoneCode",
    params: params,
  });
}

/**
 * @description: 手机号和验证码验证
 * @param {Number} password
 */

export function validRawPhone(params) {
  return request({
    method: "GET",
    url: "/staff/validRawPhone",
    params: params,
  });
}
/* 上传头像 */
export function uploadAvatar(data) {
  return request({
    url: "/cdn/upload/uploadAvatar",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      "User-Source": "APP_OA",
      // Authorization: localStorage.getItem("Authorization_OA_H5"),
      Authorization:localStorage.getItem("Authorization_OA_H5"),

    },
    data,
  });
}
/**
 * @description: 修改密码-不强制校验密码强度
 * @param {Number} password
 */

export function updatePassword(params) {
  return request({
    method: "GET",
    url: "/staff/updatePassword",
    params: params,
  });
}
