<!-- 修改密码 -->
<template>
  <div class="change-pass-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '修改密码',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <div class="form-wrapper">
      <van-form @failed="onFailed" ref="changePassForm">
        <van-field v-model="phone" required disabled placeholder="请输入手机号">
        </van-field>

        <van-field
          v-model="code"
          required
          placeholder="请输入验证码"
          name="code"
          :rules="[
            { required: true, message: '' },
            { pattern: /\w{4}/, message: '长度在 4 个字' },
          ]"
          :readonly="readonlyInput"
          @focus="readonlyInput = false"
          @blur="readonlyInput = true"
          maxlength="4"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click.native.stop="handleSend"
              >发送验证码</van-button
            >
          </template>
        </van-field>

        <van-field
          v-model="password"
          :type="rightIcon2 ? '' : 'password'"
          required
          placeholder="请输入新密码"
          :rules="[{ required: true, message: '' }]"
          name="password"
          :right-icon="rightIcon2 ? 'eye-o' : 'closed-eye'"
          @click-right-icon="
            rightIcon2 ? (rightIcon2 = false) : (rightIcon2 = true)
          "
          :readonly="readonlyInput1"
          @focus="readonlyInput1 = false"
          @blur="readonlyInput1 = true"
          maxlength="16"
          minlength="8"
        />
        <p class="remark">
          密码需包含大写字母、小写字母数字 和特殊符号，长度8-16位
        </p>
        <div class="btn-box">
          <div class="btn">
            <van-button round block type="primary" @click="onSubmit"
              >提交</van-button
            >
          </div>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import Cookies from "js-cookie";
import { revisePassword } from "@/api/setting/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";
import {
  getMyStatffInfo,
  getPhoneCode,
  updatePassword,
} from "@/api/setting/index.js";
export default {
  data() {
    return {
      userInfo: null,
      rightIcon0: false,
      rightIcon1: false,
      rightIcon2: false,
      phone: "",
      code: "",
      password: "",
      readonlyInput: true,
      readonlyInput1: true,
    };
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
    this.getUserInfo();
  },

  methods: {
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    onFailed(e) {
      console.log(e);
    },

    onSubmit(values) {
      this.$refs.changePassForm.validate(["code", "password"]).then((res) => {
        const params = {
          password: this.password,
          phone: this.phone,
          smsCode: this.code,
        };
        updatePassword(params).then((res) => {
          if (res) {
            let { code, msg } = res;

            if (code == "200") {
              Toast(msg);
              localStorage.removeItem("Authorization_OA_H5");
              localStorage.removeItem("username_H5");
              localStorage.removeItem("staffCode_H5");
              localStorage.removeItem("openId_H5");
              this.$router.replace("/login");
            }
          }
        });
      });
    },
    getUserInfo() {
      getMyStatffInfo().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.userInfo = data;
            this.phone = this.userInfo.phoneNumber;
          }
        }
      });
    },
    handleSend() {
      if (!this.phone) return Toast("未获取到用户手机号");
      getPhoneCode({ phone: this.phone }).then((res) => {
        if (res) {
          let { code, msg } = res;
          if (code == 200) {
            Toast(msg);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.change-pass-page {
  width: 100%;
  height: 100%;
  background: #fafafa;
  position: relative;
  padding-bottom: 0.2rem;
  .form-wrapper {
    padding: 0.2rem;
    .remark {
      width: 100%;
      font-size: 0.16rem;
      font-weight: 400;
      color: #999999;
      line-height: 0.2rem;
      background: #fff;
      padding: 0.2rem;
    }
    .btn-box {
      width: 90%;
      bottom: 0rem;
      margin:1rem 0.16rem 0;
      .btn {
        width: 100%;
      }
    }
  }
}
</style>
